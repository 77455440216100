import axios from 'axios'

export default async function auth({ next }) {
    const userToken = this.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
  axios
    .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CHECK_TOKEN}`, { headers })
    .then(response => {
      if (response.data.success !== true) {
        this.$router.replace({ name: 'auth-login' })
      }
      return next()
      })
    .catch(e => {
      console.log(e)
    })
  }