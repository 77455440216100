import auth from '../../middleware/auth'

export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    meta: {
      requiresAuth: true,
      middleware: auth,
    },
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/edit',
    name: 'apps-users-edit',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },
  {
    path: '/apps/users/profile',
    name: 'apps-users-profile',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/profile/profile.vue'),
  },
  // *===============================================---*
  // *--------- MATERIAL REQUESTS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/material-request',
    name: 'apps-material-request-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/material_requests/list/List.vue'),
  },
  {
    path: '/apps/material-request-detail',
    name: 'apps-material-request-detail',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/material_requests/list/Detail.vue'),
  },
  {
    path: '/apps/material-request/create',
    name: 'apps-material-request-create',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/material_requests/list/AddNew.vue'),
  },
  {
    path: '/apps/material-request/edit',
    name: 'apps-material-request-edit',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/material_requests/list/Edit.vue'),
  },
  {
    path: '/apps/material-request/confirm',
    name: 'apps-material-request-confirm',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/material_requests/list/Confirm.vue'),
  },
  {
    path: '/apps/material-request/send',
    name: 'apps-material-request-send',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/material_requests/list/Send.vue'),
  },
  {
    path: '/apps/material-request/receive',
    name: 'apps-material-request-receive',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/material_requests/list/Receive.vue'),
  },
  {
    path: '/apps/material-request/receive/list',
    name: 'apps-material-request-receive-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/material_requests/list/ReceiveList.vue'),
    },
    {
      path: '/apps/material-request/receive/detail',
      name: 'apps-material-request-receive-detail',
      meta: {
        requiresAuth: true,
      },
      component: () => import('@/views/apps/material_requests/list/ReceiveDetail.vue'),
      },
    {
      path: '/apps/material-request/receive/add',
      name: 'apps-material-request-receive-add',
      meta: {
        requiresAuth: true,
      },
      component: () => import('@/views/apps/material_requests/list/AddReceive.vue'),
  },
  {
    path: '/apps/material-request/receive/form',
    name: 'apps-material-request-receive-form',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/material_requests/list/AddReceiveForm.vue'),
},
  // *===============================================---*
  // *--------- PURCHASE REQUEST ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/purchase-request',
    name: 'apps-procurement-purchase-request-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/List.vue'),
  },
  {
    path: '/apps/purchase-request/detail',
    name: 'apps-purchase-request-detail',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/Detail.vue'),
  },
  {
    path: '/apps/purchase-request/create',
    name: 'apps-purchase-request-create',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/AddNew.vue'),
  },
  {
    path: '/apps/purchase-request/edit',
    name: 'apps-purchase-request-edit',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/Edit.vue'),
  },
  {
    path: '/apps/purchase-request/confirm/purchasing',
    name: 'apps-purchase-request-confirm-purchasing',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/ConfirmPurchasing.vue'),
  },
  {
    path: '/apps/purchase-request/confirm/lab-manager',
    name: 'apps-purchase-request-confirm-lab-manager',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/ConfirmLabManager.vue'),
  },{
    path: '/apps/purchase-request/confirm/regional-manager',
    name: 'apps-purchase-request-confirm-regional-manager',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/ConfirmRegionalManager.vue'),
  },
  {
    path: '/apps/purchase-request/confirm/division-head',
    name: 'apps-purchase-request-confirm-division-head',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/ConfirmDivisionHead.vue'),
  },
  {
    path: '/apps/purchase-request/confirm/fa-coal-manager',
    name: 'apps-purchase-request-confirm-fa-coal-manager',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/ConfirmFACoalManager.vue'),
  },
  {
    path: '/apps/purchase-request/confirm/operation-director',
    name: 'apps-purchase-request-confirm-operation-director',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/ConfirmOperationDirector.vue'),
  },
  {
    path: '/apps/purchase-request/confirm/vice-president',
    name: 'apps-purchase-request-confirm-vice-president',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/ConfirmVicePresident.vue'),
  },
  {
    path: '/apps/purchase-request/confirm/president-director',
    name: 'apps-purchase-request-confirm-president-director',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/ConfirmPresidentDirector.vue'),
  },
  {
    path: '/apps/purchase-request/confirm/procurement-pusat',
    name: 'apps-purchase-request-confirm-procurement-pusat',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/ConfirmProcurementPusat.vue'),
  },
  {
    path: '/apps/purchase-request/send',
    name: 'apps-purchase-request-send',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_request/list/Send.vue'),
  },
  // *===============================================---*
  // *--------- PR PLAN ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/pr-plan',
    name: 'apps-procurement-pr-plan-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/pr_plan/List.vue'),
  },
  // *===============================================---*
  // *--------- BRANCH ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/branch',
    name: 'apps-procurement-branch-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/branches/branch/BranchList.vue'),
  },

  // *===============================================---*
  // *--------- REGION ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/region',
    name: 'apps-procurement-region-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/regions/region/RegionList.vue'),
  },
    // *===============================================---*
  // *--------- DEPARTEMENTS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/departements',
    name: 'apps-procurement-departements-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/departements/DepartementList.vue'),
  },
  // *===============================================---*
  // *--------- STOCKS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/stocks',
    name: 'apps-stocks-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/stocks/List.vue'),
  },
  {
    path: '/apps/stocks/details',
    name: 'apps-stocks-details',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/stocks/Details.vue'),
  },
  // *===============================================---*
  // *--------- Returns ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/returns',
    name: 'apps-returns-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/returns/List.vue'),
  },
  {
    path: '/apps/returns/details',
    name: 'apps-returns-details',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/returns/Details.vue'),
  },
   // *===============================================---*
  // *--------- Transfers ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/transfers',
    name: 'apps-transfers-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/transfers/List.vue'),
  },
  {
    path: '/apps/transfers/details',
    name: 'apps-transfers-details',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/transfers/Details.vue'),
  },
  {
    path: '/apps/transfers/confirm/lab-manager',
    name: 'apps-transfers-confirm-lab-manager',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/transfers/ConfirmLabManager.vue'),
  },
  {
    path: '/apps/transfers/confirm/lab-manager/sender',
    name: 'apps-transfers-confirm-lab-manager-sender',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/transfers/ConfirmLabManagerSender.vue'),
  },
  {
    path: '/apps/transfers/confirm/warehouse/receiver',
    name: 'apps-transfers-confirm-warehouse-receiver',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/transfers/ConfirmWarehouseReceiver.vue'),
  },
  {
    path: '/apps/transfers/confirm/warehouse/sender',
    name: 'apps-transfers-confirm-warehouse-sender',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/transfers/ConfirmWarehouseSender.vue'),
  },
  {
    path: '/apps/transfers/confirm/regional-manager',
    name: 'apps-transfers-confirm-regional-manager',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/transfers/ConfirmRegionalManager.vue'),
  },
  {
    path: '/apps/transfers/confirm/division-head',
    name: 'apps-transfers-confirm-division-head',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/transfers/ConfirmDivisionHead.vue'),
  },
  {
    path: '/apps/transfers/confirm/fa-coal-manager',
    name: 'apps-transfers-confirm-fa-coal-manager',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/transfers/ConfirmFACoalManager.vue'),
  },
  {
    path: '/apps/transfers/receive',
    name: 'apps-transfers-receive',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/transfers/ConfirmReceiver.vue'),
  },
  {
    path: '/apps/transfers/new',
    name: 'apps-transfers-create',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/transfers/AddNew.vue'),
  },
  // *===============================================---*
  // *--------- PURCHASE MANAGEMENT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/purchase-order-management',
    name: 'apps-procurement-purchase-management-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/Management.vue'),
  },
  // *===============================================---*
  // *--------- PURCHASE ORDER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/purchase-order',
    name: 'apps-procurement-purchase-order-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/List.vue'),
  },
  {
    path: '/apps/purchase-order-finance',
    name: 'apps-procurement-purchase-order-list-finance',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/ListFinance.vue'),
  },
  {
    path: '/apps/purchase-order-summary',
    name: 'apps-procurement-purchase-order-list-summary',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/Summary.vue'),
  },
  {
    path: '/apps/purchase-order-summary-details',
    name: 'apps-procurement-purchase-order-list-summary-details',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/SummaryDetails.vue'),
  },
  {
    path: '/apps/purchase-order-summary-chart',
    name: 'apps-procurement-purchase-order-list-summary-chart',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/Chart.vue'),
  },
  {
    path: '/apps/purchase-order-detail',
    name: 'apps-purchase-order-detail',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/Details.vue'),
  },
  {
    path: '/apps/purchase-order-confirm',
    name: 'apps-purchase-order-confirm',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/Confirm.vue'),
  },
  {
    path: '/apps/purchase-order-confirm-early-finish',
    name: 'apps-purchase-order-confirm-early-finish',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/FinishEarlyConfirm.vue'),
  },
  {
    path: '/apps/purchase-order-early-finish',
    name: 'apps-purchase-order-early-finish',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/FinishEarly.vue'),
  },
  {
    path: '/apps/purchase-order/create',
    name: 'apps-purchase-order-create',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/AddNew.vue'),
  },
  {
    path: '/apps/purchase-order/edit',
    name: 'apps-purchase-order-edit',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/Edit.vue'),
  },
  {
    path: '/apps/purchase-order/pre/receive/purchasing',
    name: 'apps-purchase-order-pre-receive-purchasing',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/PreReceivePurchasing.vue'),
  },
  {
    path: '/apps/purchase-order/pre/receive/purchasing/detail',
    name: 'apps-purchase-order-pre-receive-purchasing-detail',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/PreReceivePurchasingDetail.vue'),
  },
  {
    path: '/apps/purchase-order/pre/receive/purchasing/new',
    name: 'apps-purchase-order-pre-receive-purchasing-new',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/AddNewPreReceivePurchasing.vue'),
  },
  {
    path: '/apps/purchase-order/pre/receive/warehouse/new',
    name: 'apps-purchase-order-pre-receive-warehouse-new',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/AddNewPreReceiveWarehouse.vue'),
  },
  {
    path: '/apps/purchase-order/pre/receive/warehouse',
    name: 'apps-purchase-order-pre-receive-warehouse',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/PreReceiveWarehouse.vue'),
  },
  {
    path: '/apps/purchase-order/pre/receive/warehouse/detail',
    name: 'apps-purchase-order-pre-receive-warehouse-detail',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/PreReceiveWarehouseDetail.vue'),
  },
  {
    path: '/apps/purchase-order/receive/purchasing',
    name: 'apps-purchase-order-receive-purchasing',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/ReceivePurchasing.vue'),
  },
  {
    path: '/apps/purchase-order/receive/warehouse',
    name: 'apps-purchase-order-receive-warehouse',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/purchase_order/ReceiveWarehouse.vue'),
  },

  // *===============================================---*
  // *--------- ASSETS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/assets',
    name: 'apps-procurement-assets-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/List.vue'),
  },
  {
    path: '/apps/assets/detail',
    name: 'apps-assets-detail',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/Details.vue'),
  },
  {
    path: '/apps/assets/history',
    name: 'apps-assets-history',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/History.vue'),
  },
  {
    path: '/apps/assets/create',
    name: 'apps-assets-create',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/AddNew.vue'),
  },
  {
    path: '/apps/assets/edit',
    name: 'apps-assets-edit',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/Edit.vue'),
  },
  {
    path: '/apps/assets/history',
    name: 'apps-assets-history',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/History.vue'),
  },
  {
    path: '/apps/assets/map',
    name: 'apps-assets-map',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/Map.vue'),
  },
  {
    path: '/apps/assets/dispose/detail',
    name: 'apps-assets-dispose-detail',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/DisposeDetail.vue'),
  },
  {
    path: '/apps/assets/dispose/finance',
    name: 'apps-assets-dispose-finance',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/DisposeFinance.vue'),
  },
  {
    path: '/apps/assets/dispose/lab_manager',
    name: 'apps-assets-dispose-lab-manager',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/DisposeLabManager.vue'),
  },
  {
    path: '/apps/assets/dispose/regional_manager',
    name: 'apps-assets-dispose-regional-manager',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/DisposeRegionalManager.vue'),
  },
  {
    path: '/apps/assets/dispose/division_head',
    name: 'apps-assets-dispose-division-head',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/DisposeDivisionHead.vue'),
  },
  {
    path: '/apps/assets/dispose/fa_coal_manager',
    name: 'apps-assets-dispose-fa-coal-manager',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/DisposeFACoalManager.vue'),
  },
  {
    path: '/apps/assets/receive',
    name: 'apps-assets-receive',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/Receive.vue'),
  },
  {
    path: '/apps/assets/receive/list',
    name: 'apps-assets-receive-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/ReceiveList.vue'),
  },
  {
    path: '/apps/assets/receive/details',
    name: 'apps-assets-receive-details',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/ReceiveDetails.vue'),
  },
  {
    path: '/apps/assets/receive/form',
    name: 'apps-assets-receive-forms',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/ReceiveForms.vue'),
  },
  {
    path: '/apps/assets/receive/confirm',
    name: 'apps-assets-confirm-forms',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/ConfirmForms.vue'),
  },
  {
    path: '/apps/assets/receive/form',
    name: 'apps-assets-receive-form',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/assets/ReceiveForm.vue'),
  },
  // *===============================================---*
  // *--------- ITEMS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/items',
    name: 'apps-items-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/items/items/ItemList.vue'),
  },
  {
    path: '/apps/items/stocks/history',
    name: 'apps-items-history-lists',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/item_stocks/items/History.vue'),
  },
  // *===============================================---*
  // *--------- SUPPLIERS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/supplier',
    name: 'apps-supplier-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/contacts/contacts/ContactList.vue'),
  },
  {
    path: '/apps/supplier/detail',
    name: 'apps-supplier-detail',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/contacts/contacts/ContactDetails.vue'),
  },
    // *===============================================---*
  // *--------- ITEMS STOCKS---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/item-stocks',
    name: 'apps-item-stocks-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/item_stocks/items/BranchList.vue'),
  },
  {
    path: '/apps/item-stocks/details',
    name: 'apps-item-stocks-details',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/item_stocks/items/BranchDetails.vue'),
  },
  {
    path: '/apps/items-stocks/create',
    name: 'apps-item-stocks-create',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/item_stocks/items/Update.vue'),
  },

  // {
  //   path: '/apps/material-request/update',
  //   name: 'apps-material-request-update',
  //   meta: {
  //     requiresAuth: true,
  //   },
  //   component: () => import('@/views/apps/material_request/MaterialRequestUpdate.vue'),
  // },
  // *===============================================---*
  // *--------- UNITS ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/unit',
    name: 'apps-unit-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/unit/unit/UnitList.vue'),
  },
  // *===== ==========================================---*
  // *--------- ROLES ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/roles/list',
    name: 'apps-roles-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/roles/roles-list/RolesList.vue'),
  },

  // Executive
  {
    path: '/apps/income/external_all',
    name: 'apps-income-external-all',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/ExternalAll.vue'),
  },
  {
    path: '/apps/income/external_all/:startDate/:endDate',
    name: 'apps-income-external-all-date',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/ExternalAll.vue'),
  },
  {
    path: '/apps/income/corporate',
    name: 'apps-income-corporate',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/Corporate.vue'),
  },
  {
    path: '/apps/income/corporate/:startDate/:endDate',
    name: 'apps-income-corporate-date',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/Corporate.vue'),
  },
  {
    path: '/apps/income/local',
    name: 'apps-income-local',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/Local.vue'),
  },
  {
    path: '/apps/income/local/:startDate/:endDate',
    name: 'apps-income-local-date',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/Local.vue'),
  },
  {
    path: '/apps/income/internal',
    name: 'apps-income-internal',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/Internal.vue'),
  },
  {
    path: '/apps/income/internal/:startDate/:endDate',
    name: 'apps-income-internal-date',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/Internal.vue'),
  },
  {
    path: '/apps/income/external_nett',
    name: 'apps-income-external-nett',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/ExternalNett.vue'),
  },
  {
    path: '/apps/income/external_nett/:startDate/:endDate',
    name: 'apps-income-external-nett-date',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/income/ExternalNett.vue'),
  },
  // *===============================================---*
  // *--------- STOCK OPNAME ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/stock-opname',
    name: 'apps-stock-opname-list',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/stock_opname/List.vue'),
  },
  {
    path: '/apps/stock-opname/detail',
    name: 'apps-stock-opname-details',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/stock_opname/Details.vue'),
  },
  {
    path: '/apps/stock-opname/create',
    name: 'apps-stock-opname-create',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/stock_opname/AddNew.vue'),
  },
  {
    path: '/apps/stock-opname/edit',
    name: 'apps-stock-opname-edit',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/stock_opname/Edit.vue'),
  },
  {
    path: '/apps/stock-opname/confirm/finance',
    name: 'apps-stock-opname-confirm-finance',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/stock_opname/ConfirmFinance.vue'),
  },
  {
    path: '/apps/stock-opname/confirm/labmanager',
    name: 'apps-stock-opname-confirm-labmanager',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/stock_opname/ConfirmLabmanager.vue'),
  },
  {
    path: '/apps/stock-opname/confirm/administrator',
    name: 'apps-stock-opname-confirm-administrator',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/stock_opname/ConfirmAdministrator.vue'),
  },
  {
    path: '/apps/settings',
    name: 'apps-settings',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/settings/settings.vue'),
  },
  {
    path: '/apps/void/list',
    name: 'apps-void',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/apps/void/List.vue'),
  },
]
