import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { isUserLoggedIn } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    ...apps,
    ...dashboard,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      router.replace({ name: 'auth-login' })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
